@import './theme';

.footerWrapper {
  margin-top: auto;
  min-height: 300px;

  a {
    color: white;
  }

  @media print {
    display: none;
  }
}

.footer {
  background: $mainColor;
  margin-top: 2rem;
  padding: 1rem;
  min-height: 250px;

  @media (min-width: map-get($breakpoints, 'md')) {
    padding: 2rem 0;
    margin-top: 3rem;
  }

  a {
    color: white;
    font-size: 1rem;
  }
}

.subFooter {
  background-color: $mainColorActive;
  color: white;
  font-size: 0.9rem;
  padding: 1rem;

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding-bottom: 112px;
  }

  a {
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    padding: 1em 0;
  }

  @media print {
    display: none;
  }
}

.navigationLinks {
  display: grid;
  margin-top: 1rem;

  @media (min-width: map-get($breakpoints, 'md')) {
    margin-top: 0;
    grid-gap: 1rem;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 0;
  }

  li {
    border-top: 1px solid white;
    position: relative;

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 0.3rem 0;
      border-top: 0;
    }
  }

  a {
    padding: 0.4rem;
    width: 100%;
    display: block;

    @media (min-width: map-get($breakpoints, 'md')) {
      display: inline;
      width: auto;
      padding: 0;
    }
  }

  .categories {
    display: none;

    @media (min-width: map-get($breakpoints, 'md')) {
      display: block;
    }
  }
}

.navigationArrow {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  fill: white;
  pointer-events: none;

  @media (min-width: map-get($breakpoints, 'md')) {
    display: none;
  }
}

.logo {
  width: 216px;
  height: 69px;
  cursor: pointer;
  margin-bottom: 2rem;
}
