@import '../layout/theme';

.previousPrice {
  text-decoration: line-through;
  color: #7a7a7a;
  margin-left: 0.5rem;
  font-size: 1em;
  display: inline-block;
}

.currentPrice {
  color: $mainColor;
  font-weight: 500;
  font-size: 1.1em;
  display: inline-block;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  color: #333;

  &:hover {
    color: $mainColorActive;
  }
}

.labels {
  position: absolute;
  left: 8px;
  top: 3px;

  @media (max-width: map-get($breakpoints, 'md')) {
    left: 0;
  }

  div {
    flex-direction: column;
    padding: 2px 4px !important;
    gap: 0.5rem;
  }
}

.productWrapper {
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &:hover {
    color: $mainColorActive;
  }
}

.description {
  color: #959090;
}

.skeletonImgWrapper {
  margin-bottom: 0.3rem;
}

.imgPlaceholder {
  width: 100%;
  height: 152px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twoCols {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  align-items: flex-start;

  > *:first-child {
    margin-right: 0.5rem;
    min-width: 0;
  }
}

.rating {
  margin: 0.3rem 0;
}

.variantsDescription {
  font-size: 0.8rem;
  color: #aaa;

  span {
    border: 1px solid #eee;
    margin: 0.3rem 0.2rem 0 0;
    padding: 0.2rem 0.4rem;
    display: inline-block;
  }
}

.addToCart {
  button {
    padding: 5px 10px !important;
  }

  margin: 0.3rem 0;
}

.button {
  padding: 5px !important;
  height: auto !important;
  margin-top: 6px;
}

.eyeIcon {
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: bottom;
}
