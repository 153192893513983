@import './theme';

.pageWithWrapper {
  box-sizing: border-box;
  max-width: $width;
  margin: 0 auto;

  @media (min-width: map-get($breakpoints, 'md')) {
    padding: 0 0.5rem;
  }
}

.paperHeader {
  padding: 0.5rem 1rem;
  margin: -1rem -1rem 1rem -1rem;
  border-bottom: 1px solid #eee;

  > h1,
  h2,
  h3 {
    margin-bottom: 0 !important;
  }
}

.richDescription {
  margin: 1rem 0;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ul,
  ol {
    list-style: auto;
    margin-left: 40px;
  }

  ul li {
    list-style-type: circle;
  }

  p {
    word-break: break-word;
  }
}

.paper {
  box-shadow: $cardBoxShadow;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;
  border-radius: 4px;

  @media print {
    box-shadow: none;
  }
}

.article {
  margin-top: 2.5rem;
}
