@import '../layout/theme';

.addToCart {
  :global {
    .button {
      padding: 10px !important;
      height: auto !important;
      width: 100%;

      @media (min-width: map-get($breakpoints, 'sm')) {
        width: initial;
      }

      .shoppingCartIcon {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: bottom;
      }
    }

    .textWrapper {
      margin-left: 0.3rem;
    }
  }
}
