.loader {
  text-align: center;
  z-index: 5;

  span {
    display: inline;
    position: relative;
    opacity: 1;
    animation: showHideDot 1.5s none infinite;

    &.one {
      animation-delay: 0.2s;
    }

    &.two {
      animation-delay: 0.4s;
    }

    &.three {
      animation-delay: 0.6s;
    }
  }

  @keyframes showHideDot {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
