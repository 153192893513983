@import 'hyperplan/dist/hyperplan.min.css';
@import 'react-loading-skeleton/dist/skeleton.css';

body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
}

select {
  font-size: inherit;
  color: #02815f;
}

::selection {
  color: white;
  background: #02815f;
}

@media print {
  body {
    background-color: #fff !important;
  }
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#main-content {
  flex: 1 0 auto;
  width: 100%;
}

#root > * {
  height: 100%;
  flex-direction: column;
}

body {
  line-height: 1.4em !important;
  font-weight: 400;
  color: #333 !important;
  overflow-x: hidden !important;
}

body,
textarea {
  font-family: Roboto, sans-serif;
}

pre {
  font-family: Roboto, sans-serif !important;
  margin: 0 !important;
  white-space: pre-wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media print {
  body {
    padding: 0 0.5rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  body {
    font-size: 15px;
  }
}

ol,
ul {
  list-style: none;
  padding: 0;
}

p {
  margin: 0 0 1rem;
  line-height: 1.4rem;
}

a {
  color: #0b7f46;
  text-decoration: none;
}

b {
  font-weight: 700;
}

h1,
h2,
h3,
h4 {
  color: #0a7f46 !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
}

h1 {
  font-size: 1.9em !important;
  margin-bottom: 1rem !important;
}

h2 {
  font-size: 1.24rem;
  color: #4e4e4e !important;
  font-weight: 500 !important;
}

h3 {
  font-size: 1.1rem;
  margin-bottom: 0 !important;
}

table th {
  text-align: left;
}

small {
  font-size: 80%;
}

.sub-title {
  color: #0a7f46 !important;
  text-transform: uppercase;
  font-size: 12px;
  margin: -20px 0 20px;
}

.static-content {
  font-size: 16px;
  line-height: 1.4em;
}

.static-content p {
  margin-bottom: 1.4rem;
}

.static-content p:last-child {
  margin-bottom: 0;
}

.static-content h1:first-child {
  margin-top: -0.5rem;
}

.static-content a {
  color: #aaca38;
  text-decoration: underline;
}

.static-content a:hover {
  color: #0b7f46;
}

.static-content table th {
  border-bottom: 3px solid #ccc;
}

.static-content tbody tr:first-child td {
  padding-top: 0.5rem;
}

.static-content ul {
  padding-left: 0.9em;
  margin: 1rem 0;
}

.static-content ul li {
  list-style-image: url('../public/images/bullet.png');
  padding-left: 0.4em;
}

.notification-container.notification-container:not(.notification-container-empty) {
  padding-bottom: 1rem;
}

.notification.notification {
  background: rgba(255, 255, 255, 0.95);
  color: #333;
  border-radius: 5px;
  opacity: 1;
}

.notification-error.notification-error {
  border: 1px solid #bd362f;
}

.notification-error.notification-error:before {
  color: #bd362f;
}

.notification-success.notification-success {
  border: 1px solid #aaca38;
}

.ant-notification-notice {
  padding: 0.5rem !important;
  border: 1px solid #ccc;
}

.ant-notification-notice.error-message {
  border-color: #f5222d;
}

.ant-notification-notice.success-message {
  border-color: #52c41a;
}

.ant-notification-notice.info-message {
  border-color: #2196f3;
}

.ant-notification-notice.info-message .anticon {
  color: #2196f3;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 38px !important;
}

.ant-notification-notice-icon {
  top: 11px !important;
}

.ant-notification-notice-close {
  top: 0.5rem !important;
  right: 0.5rem !important;
}

.ant-form-item-row {
  flex-direction: column;
}

.ant-form-item-label {
  text-align: start !important;
  line-height: 1rem !important;
}

.ant-form-item-label > .ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*' !important;
}

.ant-form-item-label > label:not(ant-form-item-required)::after {
  display: none;
}

.ant-form-item-required::before {
  display: none !important;
}

.ant-tabs-content {
  padding: 0 1rem 1rem !important;
}

a:hover,
.notification-success.notification-success:before {
  color: #aaca38;
}

.white-space-pre {
  white-space: pre;
}

.ant-tabs-nav {
  padding-left: 10px;
}

label {
  white-space: normal !important;
}

li.react-multi-carousel-dot--active button {
  background: #aaca38 !important;
}
