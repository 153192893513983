@import '../layout/theme';

.phoneIcon {
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

.favoriteIcon {
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

.userIcon {
  width: 20px;
  height: 20px;
}

.headerWrapper {
  position: relative;
  min-height: 116px;

  .menu {
    margin: 0;
    display: flex;
    flex-direction: row;

    a {
      display: inline-block;
      text-decoration: none;
      position: relative;
      transition: 0.35s background;

      &.active,
      &:hover {
        color: white;
        background-color: $mainColorActive;
      }
    }
  }

  .menuWrapper {
    position: relative;
  }

  @media (min-width: map-get($breakpoints, 'xl')) {
    height: auto;
  }
}

.headerTop {
  padding: 0.5rem 0;
  height: 53px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #ddd;

  .browser {
    display: none;
  }

  > div {
    display: grid;
    grid-template-columns: 216px auto;
    grid-column-gap: 0.7rem;
    align-items: center;

    @media (min-width: map-get($breakpoints, 'lg')) {
      grid-template-columns: 216px 0 auto;
      grid-gap: 2rem;

      .browser {
        display: block;
        margin-top: 0.5rem;
      }
    }
  }

  nav {
    display: none;
  }

  .logo {
    margin-left: 0.5rem;
    display: inline-block;

    img {
      width: 162px;
    }

    @media print {
      margin-left: 0;
    }
  }

  @media (min-width: map-get($breakpoints, 'xl')) {
    position: relative;
    border-bottom: 0;

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin: 6px 0;
    }

    .logo {
      margin-left: 0;

      img {
        width: 216px;
      }
    }
  }

  .menu {
    li {
      &:last-child a:after {
        display: none;
      }
    }

    a {
      font-size: 0.96em;
      color: $mainColor;
      height: 40px;
      padding: 0 15px 0 14px;
      margin-right: -1px;
      border-radius: 4px;

      &:after {
        background: #ddd;
        bottom: 6px;
        content: '';
        position: absolute;
        top: 6px;
        width: 1px;
        right: 0;
      }

      &:hover:after,
      &.active:after {
        display: none;
      }

      &.active:before {
        border-color: $mainColorActive transparent transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        bottom: -5px;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -5px;
        position: absolute;
        width: 0;
        pointer-events: none;
      }

      &:hover {
        color: white;
      }

      &.active {
        background-color: $mainColorActive;
        color: white;
      }
    }
  }
}

.mainMenu {
  background: rgba(59, 167, 115, 0.06);
  border-top: 1px solid #f1f1f1;
  display: none;

  @media print {
    display: none;
  }

  .menu {
    font-weight: 500;

    a {
      padding: 12px 18px;
    }

    li:last-child {
      a {
        border-right: 0;
      }
    }
  }

  @media (min-width: map-get($breakpoints, 'xl')) {
    display: block;
  }
}

.search {
  width: 100%;
  margin-right: 0.7rem;
  display: none;

  @media (min-width: map-get($breakpoints, 'xl')) {
    display: block;
  }

  @media print {
    display: none;
  }
}

.shoppingCart {
  svg {
    margin-top: 10px;
  }
}

.mobileShoppingCart {
  display: inline-block;
  position: absolute;
  right: 50px;
  pointer-events: all;
  border-right: 1px solid #ddd;
  padding: 0 1rem;
  margin-right: 1rem;
}

.mobileMenu {
  top: 20px;
  position: absolute;
  width: 100%;
  z-index: 300;
  display: block;
  height: 27px;
  pointer-events: none;

  @media (min-width: map-get($breakpoints, 'xl')) {
    display: none;
  }

  li {
    border-bottom: 1px solid #0c9753;
  }

  .mobileMenu {
    top: 38px;
    position: relative;
    background: #0a7f46;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    box-shadow: 0 5px 10px #aaa;
    pointer-events: all;

    a {
      display: block;
      padding: 0.6em 1em;
      text-decoration: none;
      color: white;

      &:hover {
        color: white;
        background: $mainColorActive;
      }

      &.active {
        background: $mainColorActive;
      }
    }
  }

  .toggle:checked ~ nav .mobileMenu {
    max-height: 350px;
    height: 340px;
    transition: max-height 0.25s ease-in;
  }

  .toggle,
  .button {
    position: absolute;
    top: 0;
    right: 10px;
    pointer-events: all;
    cursor: pointer;
  }

  .toggle {
    display: block;
    width: 33px;
    height: 27px;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
    margin: 0;
  }

  .button {
    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: $mainColor;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    span:first-child {
      transform-origin: 0 0;
    }

    span:nth-last-child(2) {
      transform-origin: 0 100%;
    }
  }

  .toggle:checked ~ .button span {
    opacity: 1;
    transform: rotate(37deg) translate(3px, 0);
  }

  .toggle:checked ~ .button span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  .toggle:checked ~ .button span:last-child {
    transform: rotate(-36deg) translate(0, 0);
  }

  @media print {
    display: none;
  }
}

.mobileSearchArea {
  padding: 0.5rem;

  @media (min-width: map-get($breakpoints, 'xl')) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.authNavLink {
  display: flex !important;
  align-items: center !important;
  max-width: 200px;
}

.userWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
