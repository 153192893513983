.splashLoader {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 110px;
    height: 80px;
  }
}
