@import '../layout/theme';

.arrowDown {
  position: absolute;
  right: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.popupMenu {
  .groupsUl {
    column-count: 4;

    > li {
      break-inside: avoid-column;

      > div {
        color: $mainColor;
        font-weight: 500;
      }
    }
  }

  .categoriesUl {
    padding: 10px 10px 20px;

    > li {
      width: 220px;
      line-height: 1.2;

      > a {
        text-decoration: none;
        color: black;
        font-size: 0.9rem;

        &.active,
        &:hover {
          color: $mainColor;
          text-decoration: underline;
        }
      }
    }
  }
}
