@import '../layout/theme';

.ul {
  list-style: disc;
  padding-left: 20px;
  margin-block-end: 8px;
  margin-block-start: 8px;
}

.badgeWrapper {
  display: flex;
  position: absolute;
  top: 6px;
  z-index: 100;
  gap: 1rem;

  @media (max-width: map-get($breakpoints, 'lg')) {
    flex-direction: column;
    gap: 0.5rem;
  }

  .badge {
    border-radius: 8px;
    border: 0;
    padding: 4px 6px;
    min-width: 90px;
    max-width: 180px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .noBadge {
    color: $mainColor;
    // with transparency
    background-color: #e0f4eeb0;
    width: 32px;
    min-width: 32px;
    font-weight: bold;
  }
}
