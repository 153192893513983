.shoppingCart {
  @media print {
    display: none;
  }
}

.cartIcon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.cartButton {
  display: inline-block;
  white-space: nowrap;
}
