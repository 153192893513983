$textColor: #333;
$mainColor: #02815f;
$mainColorActive: #aaca38;
$alternativeColor: #0b7f46;
$alternativeColorActive: #aaca38;
$alternativeByDefault: true;
$mainColorSoft: #e0f4ee;
$invalidColor: #f44336;
$alarmColorHard: #db9934;
$alarmColor: #ffedd3;
$cardBoxShadow: 0 1px 3px 0 rgba(0 0 0 / 0.15);
$width: 1200px;

$breakpoints: (
  sm: 568px,
  md: 768px,
  lg: 1024px,
  wrapper: 1120px,
  xl: 1280px
);
